import omit from 'lodash/omit'
import values from 'lodash/values'
import {
	ObservableMap,
	ObservableSet,
	action,
	autorun,
	makeAutoObservable,
	makeObservable,
	runInAction,
} from 'mobx'

import {
	addAttachmentToPositionCandidate,
	addCandidateComment,
	addCandidateEvent,
	addCandidateToPosition,
	deleteAttachmentFromPositionCandidate,
	deleteCandidateEvent,
	deletePosition,
	fetchAllPositionCandidates,
	fetchPosition,
	fetchPositionCandidate,
	fetchPositionCandidateAttachments,
	fetchPositionCandidateById,
	fetchPositionCandidates,
	fetchPositionCandidatesByAccount,
	fetchPositionCandidatesForCandidate,
	fetchPositions,
	removeCandidateFromPosition,
	removeCandidatePositionActionItem,
	runStageTriggers,
	sendPositionCandidateToAirtable,
	sendPositionCandidateToOutreach,
	updateCandidateComment,
	updateCandidateEvent,
	updateCandidateEventCompleted,
	updateCandidatePosition,
	updateCandidatePositionChecklistItem,
	updateCandidatePositionQuestionItem,
	updatePosition,
} from '@requests/positions'

import type {
	EmbeddingResource,
	ICandidate,
	ICandidateStage,
	ICard,
	IPositionCandidateActionItem,
	IPositionCandidateAttachment,
	IPositionCandidateNote,
	IPositionCandidateScheduledEvent,
	IResourceComment,
	PositionCandidateQuestionOption,
	PositionCandidateStatus,
	PositionStatus,
} from '@touchpoints/requests'
import type { IAccount, IPosition, IPositionCandidate, PositionExpanded } from '@types'

import { fetchCardsForPosition } from '@requests/cards'
import {
	findRecommendations,
	findSimilarPositionCandidates,
	findSimilarPositions,
} from '@requests/embeddings'
import { createAsyncQueue } from '@services/queue'
import { BasePosition, createRefrenceName, getBasePositionAnnotationMap } from '@touchpoints/store'
import flatten from 'lodash/flatten'
import type { RootStore } from '../root'
import { PositionSettings } from './settings'

export { createRefrenceName }

function createFetchPositionCandidateKey(positionId: string, candidateId: string) {
	return `fetch-pc:${positionId}-${candidateId}`
}

function createFetchPositionCandidatesKey(positionId: string) {
	return `fetch-pc-pos:${positionId}`
}

export class Position extends BasePosition {
	constructor(data: IPosition, account?: IAccount) {
		super(data, account)

		makeObservable(this, {
			...getBasePositionAnnotationMap(),

			replaceCandidateStageOverrides: action,
			linkToBlueprint: action,
			unlinkFromBlueprint: action,
			updateCandidateStageOverrides: action,
		})
	}

	async replaceCandidateStageOverrides(
		overrides: Record<string, Omit<ICandidateStage, 'id' | 'name'>>
	) {
		const res = await updatePosition(this.organizationId, this.accountId, this.id, {
			candidateStageOverrides: overrides,
			linkedBlueprintId: '',
		})

		if (!res.success) {
			return
		}

		const { position } = res.data ?? {}
		if (!position) {
			return
		}

		this.candidateStageOverrides = position.candidateStageOverrides
	}

	async linkToBlueprint(blueprintId: string) {
		const res = await updatePosition(this.organizationId, this.accountId, this.id, {
			linkedBlueprintId: blueprintId,
		})

		if (!res.success) {
			return
		}

		const { position } = res.data ?? {}
		if (!position) {
			return
		}

		this.linkedBlueprintId = position.linkedBlueprintId ?? ''
	}

	async unlinkFromBlueprint() {
		const res = await updatePosition(this.organizationId, this.accountId, this.id, {
			linkedBlueprintId: '',
		})

		if (!res.success) {
			return
		}

		const { position } = res.data ?? {}
		if (!position) {
			return
		}

		this.linkedBlueprintId = position.linkedBlueprintId ?? ''
	}

	async updateCandidateStageOverrides(id: string, updates: Partial<ICandidateStage>) {
		if (!this.candidateStageOverrides) {
			this.candidateStageOverrides = {}
		}

		this.candidateStageOverrides[id] = omit(updates, 'id', 'name')

		const res = await updatePosition(this.organizationId, this.accountId, this.id, {
			candidateStageOverrides: this.candidateStageOverrides,
		})

		if (!res.success) {
			return
		}

		const { position } = res.data ?? {}
		if (!position) {
			return
		}

		this.candidateStageOverrides = position.candidateStageOverrides
	}
}

class PositionCandidate implements IPositionCandidate {
	id = ''
	candidateId = ''
	positionId = ''
	stage = ''
	status: PositionCandidateStatus = 'active'

	engagementContactId?: string
	recruiterId?: string
	accountExecutiveId?: string
	supportContactId?: string

	trelloCardUrl?: string
	trelloCardId?: string
	formUrl?: string

	attachments: IPositionCandidateAttachment[] = []

	comments: IResourceComment[] = []
	actionItems?: IPositionCandidateActionItem[] = []
	scheduledEvents?: IPositionCandidateScheduledEvent[] = []

	notes: IPositionCandidateNote[] = []

	tags: string[] = []

	createdBy = ''
	createdAt = 0

	constructor(data: IPositionCandidate) {
		this.update(data)

		makeAutoObservable(this)
	}

	update(data: IPositionCandidate) {
		this.id = data.id
		this.candidateId = data.candidateId
		this.positionId = data.positionId
		this.engagementContactId = data.engagementContactId
		this.recruiterId = data.recruiterId
		this.accountExecutiveId = data.accountExecutiveId
		this.supportContactId = data.supportContactId
		this.stage = data.stage ?? this.stage
		this.status = data.status ?? 'unknown'
		this.trelloCardUrl = data.trelloCardUrl
		this.trelloCardId = data.trelloCardId
		this.formUrl = data.formUrl
		this.comments = data.comments ?? []
		this.actionItems = data.actionItems ?? []
		this.scheduledEvents = data.scheduledEvents ?? []
		this.notes = data.notes ?? []
		this.tags = data.tags ?? []
		this.createdBy = data.createdBy ?? ''
		this.createdAt = data.createdAt ?? 0
	}

	updateAttachments(attachments: IPositionCandidateAttachment[]) {
		this.attachments = [...attachments]
	}

	addComment(comment: IResourceComment) {
		this.comments.push(comment)
	}

	updateComment(commentId: string, comment: string) {
		const idx = this.comments.findIndex((c) => c.id === commentId)
		if (idx < 0) {
			return
		}

		const c = this.comments[idx]

		c.content = comment
		c.updatedAt = Date.now()

		return c
	}
}

export class PositionsStore {
	readonly settings: PositionSettings

	readonly rootStore: RootStore

	readonly _list: Position[] = []
	readonly candidateIdsByPosition = new ObservableMap<string, ObservableSet<string>>()
	readonly positionCandidateByCandidate = new ObservableMap<
		string,
		Record<string, PositionCandidate>
	>()

	private positionCandidateById = new ObservableMap<string, PositionCandidate>()

	private requestQueue = createAsyncQueue({ maxChannels: 3 })

	activePositionId: string | null = null

	get isLoading() {
		return !this.requestQueue.isEmpty()
	}

	get list() {
		return this._list.map((p) => {
			const account = this.rootStore.accounts.getAccountById(p.accountId)
			p.setAccount(account)
			return p
		})
	}

	get allCandidates() {
		const entries = this.positionCandidateById.entries()
		const candidates = []
		for (const [_, pc] of entries) {
			candidates.push(pc)
		}
		return candidates
	}

	get positionById() {
		return this._list.reduce((ret, p) => {
			ret[p.id] = p
			return ret
		}, {} as Record<string, Position>)
	}

	get positionsForActiveAccount() {
		if (!this.rootStore.accounts.activeAccountId) {
			return []
		}
		return this.list.filter((p) => p.accountId === this.rootStore.accounts.activeAccountId)
	}

	get groupedSelectOptions() {
		const positionsByAccountId = this.list.reduce((ret, p) => {
			if (!(p.accountId in ret)) {
				ret[p.accountId] = {
					id: p.id,
					name: p.account?.name ?? p.accountId,
					list: [],
				}
			}
			ret[p.accountId].list.push(p)
			return ret
		}, {} as Record<string, { id: string; name: string; list: (PositionExpanded & { referenceName: string })[] }>)

		const positionOptions: {
			label: string
			positionId: string
			options: { value: string; label: string }[]
		}[] = []
		for (const accountId in positionsByAccountId) {
			const { id, name, list } = positionsByAccountId[accountId]
			positionOptions.push({
				positionId: id,
				label: name,
				options: list.map((p) => ({
					value: p.id,
					label: `${p.referenceName} - ${p.name}`,
				})),
			})
		}

		return positionOptions
	}

	get activePosition() {
		if (!this.activePositionId) {
			return undefined
		}
		return this.getPositionById(this.activePositionId)
	}

	get activeCandidates() {
		if (!this.activePositionId) {
			return []
		}

		const set = this.candidateIdsByPosition.get(this.activePositionId)
		if (!set) {
			return []
		}

		return Array.from(set.values())
	}

	get activeCandidatesSet() {
		if (!this.activePositionId) {
			return new Set()
		}

		const set = this.candidateIdsByPosition.get(this.activePositionId)
		if (!set) {
			return new Set()
		}

		return new Set(set)
	}

	constructor(root: RootStore) {
		this.rootStore = root
		this.settings = new PositionSettings(root)

		makeAutoObservable(this, {
			rootStore: false,
			settings: false,
		})

		autorun(async () => {
			if (!this.rootStore.organizations.activeOrganizationId) {
				return
			}
			this.fetchPositions()
		})

		autorun(async () => {
			if (!this.activePositionId) {
				return
			}

			await this.fetchPosition(this.activePositionId)
			// retrieve candidates each time active position changes
			await this.fetchPositionCandidates(this.activePositionId)
		})
	}

	positionsForAccountId(accountId: string) {
		return this.list.filter((p) => p.accountId === accountId)
	}

	async fetchAllPositionsByStatus() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}
		const statuses: PositionStatus[] = [
			'open',
			'on-hold',
			'canceled',
			'filled-by-recruitful',
			'filled-by-competition',
			'contract-ended',
			'filled-by-competition',
		]
		for (const status of statuses) {
			await this.fetchAllPositionCandidates(status)
		}
	}

	async fetchPositions() {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique('fetch-positions', () =>
			fetchPositions(orgId)
		)

		if (!res.data) {
			return
		}

		const { positions, accountsById } = res.data

		runInAction(() => {
			for (const p of positions) {
				this.addPosition(p, accountsById[p.accountId])
			}
		})
	}

	async findSimilarPositions(positionId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique(`fetch-similar-positions:${positionId}`, () =>
			findSimilarPositions(orgId, positionId)
		)

		if (!res.data) {
			return
		}

		const { positions, accountsById } = res.data

		runInAction(() => {
			for (const p of positions) {
				this.addPosition(p, accountsById[p.accountId])
			}
		})

		return positions.map((p) => this.addPosition(p, accountsById[p.accountId]))
	}

	async findRecommendations(positionId: string, resourceType: EmbeddingResource) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-recommendations:${positionId}-${resourceType}`,
			() =>
				findRecommendations(orgId, {
					query: {
						positionId,
					},
					filter: {
						resourceType,
						'payload.positionId': { $ne: positionId },
					},
				})
		)

		if (!res.data) {
			return
		}

		if (resourceType === 'position') {
			const { results } = res.data
			return results.map((r) => {
				const p = r.payload as IPosition
				return {
					score: r.score,
					position: this.addPosition(p),
				}
			})
		}

		if (resourceType === 'position-candidate') {
			const { results } = res.data
			return results.map((r) => {
				const c = r.payload as ICandidate
				return {
					score: r.score,
					candidate: this.rootStore.candidates.addCandidate(c),
				}
			})
		}
	}

	async fetchPosition(positionId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			'fetch-position',
			() => fetchPosition(orgId, positionId),
			{ priority: 1 }
		)

		if (!res.data) {
			return
		}

		const { position, accountsById } = res.data
		return this.addPosition(position, accountsById[position.accountId])
	}

	async updatePosition(id: string, data: Partial<IPosition>) {
		const position = this.getPositionById(id)
		if (!position) {
			return
		}

		const res = await updatePosition(
			position.organizationId,
			position.accountId,
			position.id,
			data
		)

		if (!res.success) {
			// TODO: show error
			return
		}

		position.update({ ...position, ...data })
	}

	async deletePosition(id: string) {
		const position = this.getPositionById(id)
		if (!position) {
			return
		}

		const res = await deletePosition(position.organizationId, position.accountId, position.id)

		if (!res.success) {
			// TODO: show error
			return
		}

		this.removePosition(id)
	}

	private removePosition(id: string) {
		const idx = this._list.findIndex((p) => p.id === id)
		if (idx < 0) {
			return
		}

		this._list.splice(idx, 1)
	}

	async fetchAllPositionCandidates(status?: PositionStatus) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const key = `fetch-all:${orgId}:${status ?? 'any'}-status`
		const res = await this.requestQueue.addUnique(
			key,
			() => fetchAllPositionCandidates(orgId, status),
			{ priority: 10 }
		)

		if (!res?.data) {
			return
		}

		const { positionCandidates } = res.data
		runInAction(() => {
			for (const pc of positionCandidates) {
				const candidate = pc.candidate

				this.addPositionCandidate(new PositionCandidate(pc))

				this.addCandidate(pc.positionId, candidate ?? pc.candidateId)
				if (candidate) {
					this.rootStore.candidates.addCandidate(candidate)
				}
			}
		})
	}

	async fetchPositionCandidatesByAccount(orgId: string, accountId: string) {
		const key = `fetch-pc-by-account:${orgId}:${accountId}`
		const res = await this.requestQueue.addUnique(
			key,
			() => fetchPositionCandidatesByAccount(orgId, accountId),
			{ priority: 10 }
		)

		const positionCandidates = res?.data?.positionCandidates ?? []
		positionCandidates.forEach((pc) => {
			const candidate = pc.candidate

			this.addPositionCandidate(new PositionCandidate(pc))

			this.addCandidate(pc.positionId, candidate ?? pc.candidateId)
			if (candidate) {
				this.rootStore.candidates.addCandidate(candidate)
			}
		})

		return res
	}

	async fetchPositionCandidate(
		positionId: string,
		candidateId: string,
		options: { priority?: number; force?: boolean } = {}
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionId || !candidateId) {
			return
		}

		const pos = this.getPositionById(positionId)
		if (!pos) {
			return
		}

		const { priority, force } = options

		if (force) {
			// remove from cache to ensure we get new data
			this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))
		}

		const res = await this.requestQueue.addUnique(
			createFetchPositionCandidateKey(positionId, candidateId),
			() => fetchPositionCandidate(orgId, pos.accountId, positionId, candidateId),
			{ priority }
		)

		if (!res.data) {
			return
		}

		const { positionCandidate } = res.data

		let pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			const candidate = positionCandidate.candidate

			pc = this.addPositionCandidate(new PositionCandidate(positionCandidate))

			this.addCandidate(pos.id, candidate ?? positionCandidate.candidateId)
			if (candidate) {
				this.rootStore.candidates.addCandidate(candidate)
			}
		} else {
			pc.update(positionCandidate)
		}

		return pc
	}

	async fetchPositionCandidateById(
		positionCandidateId: string,
		options: { priority?: number } = {}
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionCandidateId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-position-candidate-by-id-${positionCandidateId}`,
			() => fetchPositionCandidateById(orgId, positionCandidateId),
			{ priority: options.priority ?? 5 }
		)

		if (!res.data) {
			return
		}

		const { positionCandidate } = res.data

		const posId = positionCandidate.positionId
		const pc = this.getPositionCandidate(positionCandidate.candidateId, posId)
		if (!pc) {
			const candidate = positionCandidate.candidate

			this.addPositionCandidate(new PositionCandidate(positionCandidate))

			this.addCandidate(posId, candidate ?? positionCandidate.candidateId)
			if (candidate) {
				this.rootStore.candidates.addCandidate(candidate)
			}
		} else {
			pc.update(positionCandidate)
		}
	}

	async fetchPositionCandidates(positionId: string, force = false) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionId) {
			return
		}

		const pos = this.getPositionById(positionId)
		if (!pos) {
			return
		}

		if (force) {
			// remove from cache to ensure we get new data
			this.requestQueue.deleteCache(createFetchPositionCandidatesKey(positionId))
		}

		const res = await this.requestQueue.addUnique(
			createFetchPositionCandidatesKey(positionId),
			() => fetchPositionCandidates(orgId, pos.accountId, positionId),
			{ priority: 1 }
		)

		if (!res.data) {
			return
		}

		const { positionCandidates } = res.data
		positionCandidates.forEach((pc) => {
			const candidate = pc.candidate

			this.addPositionCandidate(new PositionCandidate(pc))

			this.addCandidate(pos.id, candidate ?? pc.candidateId)
			if (candidate) {
				this.rootStore.candidates.addCandidate(candidate)
			}
		})
		return positionCandidates
	}

	async fetchCardsForPosition(positionId: string): Promise<ICard[]> {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionId) {
			return []
		}

		const pos = this.getPositionById(positionId)
		if (!pos) {
			return []
		}

		const res = await this.requestQueue.addUnique(
			`${createFetchPositionCandidatesKey(positionId)}-cards`,
			() => fetchCardsForPosition(orgId, positionId),
			{ priority: 1 }
		)

		if (!res.data || !res.data.cards) {
			return []
		}

		return res.data.cards
	}

	async findSimilarCandidates(
		pcId: string,
		excludeSameAccount?: boolean,
		excludeSamePosition?: boolean
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-similar-pc:${pcId}?sameAccount=${excludeSameAccount}&samePosition=${excludeSamePosition}`,
			() =>
				findSimilarPositionCandidates(orgId, pcId, excludeSameAccount, excludeSamePosition),
			{ priority: 1 }
		)

		if (!res.data) {
			return
		}

		const { cards } = res.data
		return cards
	}

	async fetchPositionCandidatesForCandidate(candidateId: string, prioritize = false) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !candidateId) {
			return
		}

		const res = await this.requestQueue.addUnique(
			`fetch-pc-for-candidate:${candidateId}`,
			() => fetchPositionCandidatesForCandidate(orgId, candidateId),
			{
				priority: prioritize ? 1 : undefined,
			}
		)

		runInAction(() => {
			if (!res.data) {
				return
			}

			const { positionCandidates, positions } = res.data
			for (const position of positions) {
				this.addPosition(position)
			}

			positionCandidates.forEach((pc) => {
				const candidate = pc.candidate
				const pos = this.getPositionById(pc.positionId)

				this.addPositionCandidate(new PositionCandidate(pc))

				if (pos) {
					this.addCandidate(pos.id, candidate ?? pc.candidateId)
				}

				if (candidate) {
					this.rootStore.candidates.addCandidate(candidate)
				}
			})
		})
	}

	setActivePositionId(id: string) {
		this.activePositionId = id
	}

	clearActivePositionId() {
		this.activePositionId = null
	}

	addPosition(data: Position | IPosition, account?: IAccount) {
		if (data.id in this.positionById) {
			const pos = this.positionById[data.id]
			pos.update(data)
			return pos
		}

		const obj =
			data instanceof Position
				? data
				: new Position(
						data,
						account ? account : this.rootStore.accounts.getAccountById(data.accountId)
				  )
		this._list.push(obj)
		return obj
	}

	async createPositionCandidate(
		position: IPosition | Position,
		candidate: string | Pick<ICandidate, 'id'>
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const candidateId = typeof candidate === 'string' ? candidate : candidate.id
		const res = await addCandidateToPosition(
			orgId,
			position.accountId,
			position.id,
			candidateId
		)
		if (!res.data) {
			return
		}

		const { positionCandidate } = res.data
		const pc = this.addPositionCandidate(positionCandidate)
		this.addCandidate(position.id, candidateId)

		return pc
	}

	addCandidate(posId: string, candidate: string | Pick<ICandidate, 'id'>) {
		const candidateId = typeof candidate === 'string' ? candidate : candidate.id
		if (!this.candidateIdsByPosition.has(posId)) {
			this.candidateIdsByPosition.set(posId, new ObservableSet())
		}

		this.candidateIdsByPosition.get(posId)?.add(candidateId)
	}

	async removeCandidate(
		position: Position | IPosition | string,
		candidate: string | Pick<ICandidate, 'id'>
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const pos = typeof position === 'string' ? this.getPositionById(position) : position
		if (!pos) {
			return
		}

		const posId = pos.id
		const candidateId = typeof candidate === 'string' ? candidate : candidate.id
		if (!this.candidateIdsByPosition.has(posId)) {
			return
		}

		const res = await removeCandidateFromPosition(orgId, pos.accountId, pos.id, candidateId)
		if (!res.success) {
			// TODO: handle error
			return
		}

		this.candidateIdsByPosition.get(posId)?.delete(candidateId)
	}

	removePositionCandidate(positionId: string, candidateId: string) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const lookup = this.positionCandidateByCandidate.get(candidateId)
		if (lookup) {
			delete lookup[pc.id]
		}

		this.candidateIdsByPosition.get(positionId)?.delete(candidateId)
	}

	getPositionById(id: string) {
		if (!id) {
			return undefined
		}
		const pos = this.positionById[id] ?? this.list.find((acc) => acc.id === id)
		if (pos && !pos.account) {
			this.expandPosition(pos)
		}
		return pos
	}

	expandPosition(data: Position) {
		data?.setAccount(this.rootStore.accounts.getAccountById(data.accountId))
		return data
	}

	getPositionCandidatesForCandidate(candidateId: string) {
		const positionCandidates = this.positionCandidateByCandidate.get(candidateId) ?? {}
		return values(positionCandidates)
	}

	addPositionCandidate(pc: PositionCandidate | IPositionCandidate) {
		if (!this.positionCandidateByCandidate.has(pc.candidateId)) {
			this.positionCandidateByCandidate.set(pc.candidateId, makeAutoObservable({}))
		}

		const lookup = this.positionCandidateByCandidate.get(pc.candidateId)
		if (!lookup) {
			return
		}

		const existing = lookup[pc.id]
		if (existing) {
			existing.update(pc)
		} else {
			const positionCandidate =
				pc instanceof PositionCandidate ? pc : new PositionCandidate(pc)

			lookup[pc.id] = positionCandidate

			if (!this.positionCandidateById.has(pc.id)) {
				this.positionCandidateById.set(pc.id, positionCandidate)
			}
		}

		return lookup[pc.id]
	}

	getPositionCandidateById(pcId: string) {
		return this.positionCandidateById.get(pcId)
	}

	getPositionCandidate(candidateId: string, positionId: string) {
		const positionCandidates = this.getPositionCandidatesForCandidate(candidateId)
		const idx = positionCandidates.findIndex((pc) => pc.positionId === positionId)
		if (idx < 0) {
			return undefined
		}
		return positionCandidates[idx]
	}

	getLastPositionCandidate(candidateId: string) {
		const list = this.getPositionCandidatesForCandidate(candidateId)
		return list[list.length - 1]
	}

	async setPositionCandidateStage(candidateId: string, positionId: string, stage: string) {
		return this.updatePositionCandidate(candidateId, positionId, { stage })
	}

	async runStageTrigger(candidateId: string, positionId: string) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return false
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return false
		}

		const pos = this.getPositionById(pc.positionId)
		if (!pos) {
			return false
		}

		const res = await runStageTriggers(orgId, pos.accountId, pc.positionId, pc.candidateId)

		if (!res.success) {
			console.error(res.message)
			return false
		}

		return true
	}

	async updatePositionCandidate(
		candidateId: string,
		positionId: string,
		data: Partial<
			Pick<
				IPositionCandidate,
				| 'engagementContactId'
				| 'recruiterId'
				| 'accountExecutiveId'
				| 'supportContactId'
				| 'stage'
				| 'status'
				| 'trelloCardUrl'
				| 'tags'
			>
		>
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return false
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return false
		}

		const pos = this.getPositionById(pc.positionId)
		if (!pos) {
			return false
		}

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		// update locally optimistically
		pc.engagementContactId = data.engagementContactId ?? pc.engagementContactId
		pc.recruiterId = data.recruiterId ?? pc.recruiterId
		pc.accountExecutiveId = data.accountExecutiveId ?? pc.accountExecutiveId
		pc.supportContactId = data.supportContactId ?? pc.supportContactId
		pc.stage = data.stage ?? pc.stage
		pc.status = data.status ?? pc.status
		pc.trelloCardUrl = data.trelloCardUrl ?? pc.trelloCardUrl
		pc.tags = data.tags ?? []

		// make API call to update
		const res = await updateCandidatePosition(
			orgId,
			pos.accountId,
			pc.positionId,
			pc.candidateId,
			data
		)

		if (!res.success) {
			console.error(res.message)
			return false
		}

		this.requestQueue.updateCache(createFetchPositionCandidateKey(positionId, candidateId), res)

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return false
		}

		pc.update(positionCandidate)

		return true
	}

	async sendToOutreach(positionCandidate: IPositionCandidate) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await sendPositionCandidateToOutreach(orgId, positionCandidate.id)

		return res.success
	}

	async sendToAirtable(positionCandidate: IPositionCandidate) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await sendPositionCandidateToAirtable(orgId, positionCandidate.id)

		return res.success
	}

	async addAttachmentToPositionCandidate(
		candidateId: string,
		positionId: string,
		data: FormData,
		_orgId?: string
	) {
		const orgId = _orgId || this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await addAttachmentToPositionCandidate(orgId, positionId, candidateId, data)

		if (!res.success) {
			console.error(res.message)
			return
		}

		return res.data
	}

	async deleteAttachmentToPositionCandidate(
		candidateId: string,
		positionId: string,
		attachmentId: string
	) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await deleteAttachmentFromPositionCandidate(
			orgId,
			positionId,
			candidateId,
			attachmentId
		)

		if (!res.success) {
			console.error(res.message)
			return res
		}

		return res
	}

	async fetchPositionCandidateAttachments(positionId: string, candidateId: string) {
		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId || !positionId) {
			return
		}

		const pos = this.getPositionById(positionId)
		if (!pos) {
			console.log('no pos for id', orgId, positionId, candidateId)
			return
		}

		const res = await fetchPositionCandidateAttachments(orgId, positionId, candidateId)
		if (!res.data) {
			return
		}

		const attachments = res.data
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		pc.updateAttachments(attachments)
		return attachments
	}

	async updateCandidateChecklistItem(
		candidateId: string,
		positionId: string,
		actionItemId: string,
		checklistItemId: string,
		value: boolean
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateCandidatePositionChecklistItem(
			orgId,
			positionId,
			candidateId,
			actionItemId,
			checklistItemId,
			{
				completed: value,
			}
		)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	async updateCandidateQuestionItem(
		candidateId: string,
		positionId: string,
		actionItemId: string,
		questionItemId: string,
		value: { text?: string; options?: PositionCandidateQuestionOption[] }
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateCandidatePositionQuestionItem(
			orgId,
			positionId,
			candidateId,
			actionItemId,
			questionItemId,
			{
				...value,
			}
		)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	async removeActionItem(candidateId: string, positionId: string, actionItemId: string) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		// optimistically update
		runInAction(() => {
			if (!pc.actionItems) {
				return
			}

			pc.actionItems = pc.actionItems.filter((ai) => ai.id !== actionItemId)
		})

		const res = await removeCandidatePositionActionItem(
			orgId,
			positionId,
			candidateId,
			actionItemId
		)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	async addCandidateEvent(
		candidateId: string,
		positionId: string,
		data: Partial<Omit<IPositionCandidateScheduledEvent, 'id'>>
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await addCandidateEvent(orgId, positionId, candidateId, data)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		runInAction(() => {
			pc.update(positionCandidate)
		})
	}

	async updateCandidateScheduledEvent(
		candidateId: string,
		positionId: string,
		eventId: string,
		data: Partial<IPositionCandidateScheduledEvent>
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateCandidateEvent(orgId, positionId, candidateId, eventId, data)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	async deleteCandidateEvent(candidateId: string, positionId: string, eventId: string) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await deleteCandidateEvent(orgId, positionId, candidateId, eventId)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	async updateCandidateEventCompleted(
		candidateId: string,
		positionId: string,
		eventId: string,
		value: boolean
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const res = await updateCandidateEventCompleted(
			orgId,
			positionId,
			candidateId,
			eventId,
			value
		)

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		if (!res.success) {
			console.error(res.message)
			return
		}

		const { positionCandidate } = res.data ?? {}
		if (!positionCandidate) {
			return
		}

		pc.update(positionCandidate)
	}

	getPositionsForCandidate(candidateId: string) {
		const positionIds: string[] = []
		for (const entry of this.candidateIdsByPosition.entries()) {
			const posId = entry[0]
			const set = entry[1]
			if (set.has(candidateId)) {
				positionIds.push(posId)
			}
		}

		return positionIds.map((id) => this.getPositionById(id)).filter((p) => !!p) as Position[]
	}

	async getCandidatesForPosition(positionId: string) {
		const candidateIds = this.candidateIdsByPosition.get(positionId)
		if (!candidateIds) {
			return []
		}

		return flatten(
			await Promise.all(
				Array.from(candidateIds).map((id) =>
					this.rootStore.candidates.getCandidateByIdAsync(id)
				)
			)
		).filter((e) => !!e) as ICandidate[]
	}

	async addCandidateComment(
		candidateId: string,
		positionId: string,
		comment: string,
		options: {
			plainComment?: string
			taskId?: string
		} = {}
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		const { plainComment, taskId } = options ?? {}

		const res = await addCandidateComment(orgId, positionId, candidateId, comment, {
			plainComment,
			taskId,
		})

		if (!res.success) {
			console.error(res.message)
			return
		}

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		const { comment: commentAdded } = res.data ?? {}
		if (!commentAdded) {
			console.error('Comment added but not present in response', commentAdded)
			return
		}

		// optimistically add locally; this will be updated from a refresh of data later
		const now = new Date().getTime()
		pc.addComment({
			id: commentAdded.id,
			createdAt: now,
			createdBy: this.rootStore.organizationUsers.activeOrgUserId,
			content: comment,
		})
	}

	async updateCandidateComment(
		candidateId: string,
		positionId: string,
		commentId: string,
		comment: string
	) {
		const pc = this.getPositionCandidate(candidateId, positionId)
		if (!pc) {
			return
		}

		const orgId = this.rootStore.organizations.activeOrganizationId

		if (!orgId) {
			return
		}

		// optimistically update
		const originalComment = pc.comments.find((c) => c.id === commentId)
		pc.updateComment(commentId, comment)

		const res = await updateCandidateComment(orgId, positionId, candidateId, commentId, comment)

		if (!res.success) {
			console.error(res.message)
			// revert
			pc.updateComment(commentId, originalComment?.content ?? '')
			return
		}

		this.requestQueue.deleteCache(createFetchPositionCandidateKey(positionId, candidateId))

		return pc.updateComment(commentId, comment)
	}
}
