import { Badge, Button, Loading, Tooltip } from '@nextui-org/react'
import { autorun, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import {
	HiArrowCircleUp,
	HiDotsHorizontal,
	HiExternalLink,
	HiOutlineEye,
	HiOutlineMail,
	HiTrash,
	HiUpload,
} from 'react-icons/hi'

import { Dropdown, Dropzone, SlidePanel, Table, Tabs, toast, useConfirm } from '@touchpoints/ui'

import { store as cardDetailedViewStore } from '@components/accounts/store'
import { Text } from '@nextui-org/react'
import { candidateAttachmentUrl } from '@requests/attachments'
import { formatTs } from '@services/time'
import { rootStore } from '@store'
import { useReaction } from '@touchpoints/mobx-hooks'
import {
	ICandidate,
	ICandidateAttachment,
	IMessage,
	IPosition,
	IPositionCandidate,
	IThread,
} from '@touchpoints/requests'
import { DEFAULT_TIMEZONE } from '@touchpoints/time'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import spacetime from 'spacetime'
import { CandidateEmail } from './CandidateEmail'
import { CandidateActivityTab } from './CandidateEvents'
import { CandidateForm } from './CandidateForm'
import { CandidateInfo } from './CandidateInfo'
import { CandidateInfoHeader } from './CandidateInfoHeader'
import { CandidatePositionList } from './CandidatePositionList'
import { CandidateSequences } from './CandidateSequences'
import { PositionCandidateStage } from './PositionCandidateStage'
import { Pencil } from '@touchpoints/icons'
import { candidateSlidingPanelStore } from './store'
import { useDraft } from '@hooks/useDraft'

type Props = {
	candidate: ICandidate
	expandable?: boolean
	onDeleted?: () => void
}

enum TabKey {
	Info = 'info',
	Sequences = 'sequences',
	Positions = 'positions',
	Events = 'events',
	Emails = 'emails',
	Resume = 'resume',
}

const store = makeAutoObservable({
	activeTab: TabKey.Info,
	setTab(key: TabKey) {
		this.activeTab = key
	},
})

export const CandidatePanel = observer(function ({ candidate: cand, onDeleted }: Props) {
	const router = useRouter()
	const [open, setOpen] = useState(false)
	const [candidate, setCandidate] = useState({ ...cand })
	const draft = useDraft(candidate)
	// const [hasChanges, setHasChanges] = useState(false)
	const [loading, setLoading] = useState({ update: false })

	const confirm = useConfirm()

	const { t } = router.query as { t?: TabKey }

	useEffect(() => {
		setCandidate({ ...cand })
	}, [cand])

	useEffect(() => {
		if (!t) {
			return
		}

		store.setTab(t)
	}, [t])

	// useEffect(() => {
	// 	setData({ ...candidate })
	// }, [candidate])

	// useEffect(() => {
	// 	setHasChanges(!isEqual(data, { ...candidate }))
	// }, [data, candidate])

	const handleUpdateCandidate = async () => {
		const orgId = rootStore.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		setLoading((prev) => ({ ...prev, update: true }))

		const res = await rootStore.candidates.updateCandidate(candidate.id, orgId, draft.value)

		setLoading((prev) => ({ ...prev, update: false }))

		if (!res) {
			toast.error('Failed to update candidate')
			return
		}
		setEdit(false)
		// setHasChanges(false)

		const updated = rootStore.candidates.getCandidateById(candidate.id)
		if (updated) {
			setCandidate(updated)
		}
	}

	const handleTabChanged = (v: string) => {
		store.setTab(v as TabKey)
		if (store.activeTab === TabKey.Events) {
			rootStore.candidates.events.fetchCandidateEvents(candidate.id)
		}

		// add tab to url
		router.push(
			{
				query: {
					...router.query,
					t: v,
				},
			},
			undefined,
			{ shallow: true }
		)
	}

	const handleDeleteCandidate = async () => {
		const shouldContinue = await confirm({
			title: 'Delete Candidate',
			message: 'Are you sure you want to delete this candidate?',
			confirmText: 'Delete',
			confirmColor: 'error',
		})

		if (!shouldContinue) {
			return
		}

		const res = await rootStore.candidates.removeCandidate(candidate)
		if (!res) {
			return
		}

		setOpen(false)
		router.push('/candidates')
		onDeleted?.()
	}

	const [edit, setEdit] = useState(false)

	return (
		<div className="flex flex-col space-y-6 p-6 w-full">
			<div className="flex flex-col space-y-6 p-6 w-full">
				<CandidateInfoHeader candidate={candidate}>
					<div>
						<Dropdown
							side="bottom"
							align="end"
							open={open}
							onCloseAutoFocus={() => setOpen(false)}
							onEscapeKeyDown={() => setOpen(false)}
							onPointerDownOutside={() => setOpen(false)}
							onFocusOutside={() => setOpen(false)}
							onInteractOutside={() => setOpen(false)}
							trigger={
								<div
									className={clsx(
										'flex items-center space-x-2 text-[#687076] border shadow-md rounded-md px-1 py-1 hover:bg-slate-50 cursor-pointer',
										{
											'bg-slate-100': open,
										}
									)}
									onClick={() => setOpen(!open)}
								>
									<HiDotsHorizontal size={20} />
								</div>
							}
						>
							<div className="w-[200px]">
								<Dropdown.Item
									onClick={() => {
										setEdit(true)
										setOpen(false)
									}}
								>
									<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
										<Pencil />
										&nbsp; Edit
									</div>
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										window.open(
											`${process.env.NEXT_PUBLIC_APP_URL}/resumes/${candidate.id}`,
											'_blank'
										)
									}}
								>
									<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
										<HiUpload />
										&nbsp; Resume
									</div>
								</Dropdown.Item>
								<Dropdown.Item onClick={handleDeleteCandidate}>
									<div className="flex items-center space-x-2 text-slate-500 px-2 py-1">
										<HiTrash size={20} className="text-red-400" />
										&nbsp; Delete candidate
									</div>
								</Dropdown.Item>
							</div>
						</Dropdown>
					</div>
				</CandidateInfoHeader>
				<CandidatePositionList candidate={candidate} />
			</div>

			<div className="flex h-full">
				<Tabs value={store.activeTab} onValueChange={handleTabChanged}>
					<Tabs.Bar>
						<Tabs.Item value={TabKey.Info}>Info</Tabs.Item>
						<Tabs.Item value={TabKey.Sequences}>Sequences</Tabs.Item>
						<Tabs.Item value={TabKey.Positions}>Positions</Tabs.Item>
						<Tabs.Item value={TabKey.Events}>Activity</Tabs.Item>
						<Tabs.Item value={TabKey.Emails}>Emails</Tabs.Item>
						<Tabs.Item value={TabKey.Resume}>Resume</Tabs.Item>
					</Tabs.Bar>
					<Tabs.Content value={TabKey.Info} className="h-full overflow-y-auto w-full">
						<div className="flex justify-between">
							<div className="grow">
								<CandidateInfo candidate={candidate} />
							</div>

							<div className="cursor-pointer" onClick={() => setEdit(true)}>
								<Pencil />
							</div>
						</div>

						<SlidePanel
							width={`30%`}
							isOpen={!!edit}
							onRequestClose={() => setEdit(false)}
							hideHeader
							panelContenClassName="p-0"
						>
							{candidate && (
								<div className="flex flex-col">
									<div className="sticky top-0 border-b bg-white p-6">
										<CandidateInfoHeader candidate={candidate} />
									</div>

									<CandidateForm
										candidate={draft.value}
										showLastPosition={true}
										onFieldChange={(field, val) => {
											draft.update({ [field]: val })
											// setData((d) => ({
											// 	...d,
											// 	[field]: val,
											// }))
										}}
									/>

									<div className="sticky bottom-0 border-t p-3 space-x-3 rounded-b-md bg-white flex justify-end">
										<button
											className="px-3 py-2 border rounded-md font-light hover:bg-slate-50 cursor-pointer"
											onClick={() => setEdit(false)}
											disabled={loading.update}
										>
											Cancel
										</button>
										<button
											className="px-3 py-2 border rounded-md font-light text-white bg-blue-600 hover:bg-blue-500 cursor-pointer"
											onClick={handleUpdateCandidate}
											disabled={!draft.hasChanges || loading.update}
										>
											{loading.update ? <Loading /> : 'Update'}
										</button>
									</div>
								</div>
							)}
						</SlidePanel>
					</Tabs.Content>
					<Tabs.Content
						value={TabKey.Sequences}
						style={{ padding: '0px', paddingTop: '10px' }}
						className="h-96 overflow-y-auto"
					>
						<CandidateSequences candidateId={candidate.id} />
					</Tabs.Content>
					<CandidatePositionsTab candidate={{ ...candidate }} />
					{store.activeTab === TabKey.Events && (
						<CandidateActivityTab tabValue={TabKey.Events} candidateId={candidate.id} />
					)}
					<CandidateEmailsTab candidateId={candidate.id} />
					<CandidateResumeTab candidate={{ ...candidate }} />
				</Tabs>
			</div>
		</div>
	)
})

const EmailContent = observer(function ({
	thread,
	message,
}: {
	thread: IThread
	message: IMessage
}) {
	return (
		<div className="pt-1.5 max-w-80" data-tag="allowRowEvents">
			<p className="text-sm font-medium text-gray-700 truncate ..." data-tag="allowRowEvents">
				{thread.subject}
			</p>
			<div className="w-80">
				<p className="text-sm text-gray-400 truncate ..." data-tag="allowRowEvents">
					{message.textContent}
				</p>
			</div>
		</div>
	)
})

const EmailState = observer(function ({ thread }: { thread: IThread }) {
	thread.opened.sort((a, b) => a - b)

	const userTimezone = rootStore.users.loggedInUser?.timezone
	const lastOpened = thread.opened[0]
		? spacetime(thread.opened[0])
				.goto(userTimezone || DEFAULT_TIMEZONE)
				.format('MMMM do, yyyy')
		: undefined
	const openedTooltip = lastOpened ? `Opened ${formatTs(thread.opened[0])}` : 'No views'

	return (
		<div className="flex flex-row space-x-3">
			<Tooltip
				placement={'leftStart'}
				content={<p>Delivered on {formatTs(thread.timestamp)}</p>}
			>
				<HiOutlineMail size={30} color={'#3958bf'} />
			</Tooltip>
			<Tooltip placement={'leftStart'} content={<p>{openedTooltip}</p>}>
				{lastOpened && (
					<Badge disableOutline content={thread.opened.length} color="primary" size="xs">
						<HiOutlineEye size={30} color={lastOpened ? '#3958bf' : 'grey'} />
					</Badge>
				)}
				{!lastOpened && <HiOutlineEye size={30} color={lastOpened ? '#3958bf' : 'grey'} />}
			</Tooltip>
		</div>
	)
})

type CandidateEmailHistoryProps = {
	candidateId: string
}
export const CandidateEmailsTab = observer(function ({ candidateId }: CandidateEmailHistoryProps) {
	const [selectedEmail, setSelectedEmail] = useState<IThread | undefined>(undefined)
	const [emails, setEmails] = useState<IThread[]>([])
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)

	useEffect(() => {
		rootStore.candidates.history.fetchEmailThreads(candidateId).then(() => {
			setEmails(
				(rootStore.candidates.history.listByCandidateId.get(candidateId) as IThread[]) ?? []
			)
		})
	}, [candidateId, orgId])

	const columns: TableColumn<IThread>[] = [
		{
			name: 'Recipients',
			selector: (row: IThread) =>
				row.recipients.length > 1
					? `${row.recipients[0]} +${row.recipients.length - 1}`
					: row.recipients[0],
		},
		{
			name: 'Content',
			grow: 4,
			cell: (row: IThread) => <EmailContent thread={row} message={row.messages[0]} />,
		},
		{
			name: 'State',
			cell: (row: IThread) => <EmailState thread={row} />,
		},
	]

	const handleSelectEmail = (email: IThread) => {
		setSelectedEmail(email)
	}

	if (rootStore.candidates.history.isLoading) {
		return <Loading size="lg" className="mt-10" />
	}

	return (
		<Tabs.Content
			value={TabKey.Emails}
			style={{ padding: '0px', paddingTop: '10px' }}
			className="h-96 overflow-y-auto"
		>
			<DataTable
				columns={columns}
				data={emails ?? []}
				onRowClicked={handleSelectEmail}
				striped
				customStyles={{
					table: {
						style: {
							maxWidth: '100%',
						},
					},
					rows: {
						style: {
							cursor: 'pointer',
						},
					},
				}}
			/>

			{selectedEmail && (
				<CandidateEmail
					isOpen={!!selectedEmail}
					onRequestClose={() => setSelectedEmail(undefined)}
					thread={selectedEmail}
				/>
			)}
		</Tabs.Content>
	)
})

type CandidateResumeProps = {
	candidate: ICandidate
}
const CandidateResumeTab = observer(function ({ candidate }: CandidateResumeProps) {
	const [uploadingFile, setUploadingFile] = useState(false)
	const [attachements, setAttachments] = useState<ICandidateAttachment[]>([])

	useEffect(() => {
		if (candidate.id)
			rootStore.candidates.resumes
				.getAllResumesForCandidate(candidate.id)
				.then((attachments) => {
					if (attachments) setAttachments(attachments)
				})
	}, [candidate.id])

	const deleteAttachment = async (attachment: ICandidateAttachment) => {
		if (!candidate) return

		rootStore.candidates.resumes
			.deleteResumeFromCandidate(candidate.id, attachment.id)
			.then((success) => {
				if (success) {
					setAttachments((prev) => prev.filter((a) => a.id !== attachment.id))
					toast.success(`Attachment ${attachment.filename} deleted`)
				} else {
					toast.error('Unexpected error while resume')
				}
			})
	}

	const columns = [
		{
			name: 'Name',
			cell: (row: ICandidateAttachment) => (
				<div data-tag="allowRowEvents" className="flex flex-row justify-between w-full">
					<p data-tag="allowRowEvents" className="m-2">
						{row.filename}
					</p>
					<Tooltip content="Remove" placement="left" offset={-10}>
						<Button
							auto
							light
							color="error"
							icon={<HiTrash />}
							onClick={() => deleteAttachment(row)}
						/>
					</Tooltip>
				</div>
			),
		},
	]

	const uploadFile = async (file: File) => {
		if (!candidate.id) return

		const data = new FormData()
		data.append(`file`, file, file.name)

		const response = await rootStore.candidates.resumes.addResumeToCandidate(candidate.id, data)

		if (response?.success && response?.doc) {
			const attachment = response.doc
			setAttachments((oldAttachments) => [...oldAttachments, attachment])
		} else {
			const description = response?.message ? `: ${response?.message}` : ''
			toast.error('Error uploading file' + description)
		}
	}
	const onDrop = async (acceptedFiles: File[]) => {
		setUploadingFile(true)
		for (const file of acceptedFiles) {
			await uploadFile(file)
		}
		setUploadingFile(false)
	}

	const onRowClicked = (row: ICandidateAttachment) => {
		window.open(
			candidateAttachmentUrl(row.resourceId, row.id, row.filename),
			'_blank',
			'noreferrer'
		)
	}
	return (
		<Tabs.Content
			value={TabKey.Resume}
			style={{ padding: '0px', paddingTop: '10px' }}
			className="h-96 overflow-y-auto"
		>
			<div>
				<div className="mb-12">
					{attachements.length <= 0 ? (
						<Text css={{ textAlign: 'center' }}>No Resume yet</Text>
					) : (
						<Table columns={columns} rows={attachements} onRowClicked={onRowClicked} />
					)}
				</div>
				<Dropzone onDrop={onDrop} uploading={uploadingFile} />
			</div>
		</Tabs.Content>
	)
})

type PositionsTabProps = {
	candidate: ICandidate
}
function CandidatePositionsTab({ candidate }: PositionsTabProps) {
	const router = useRouter()

	const { c } = router.query as { c?: string }

	const lookup = useReaction(
		() => {
			const pcs = rootStore.positions.positionCandidateByCandidate.get(candidate.id)
			return { ...pcs }
		},
		100,
		[candidate]
	)

	const positions = useReaction(
		() =>
			Object.values(lookup)
				.map((pc) => rootStore.positions.getPositionById(pc.positionId))
				.filter((p) => p) as IPosition[],
		100,
		[lookup]
	)

	useEffect(() => {
		if (!c) {
			cardDetailedViewStore.close()
			return
		}

		candidateSlidingPanelStore.close()
		cardDetailedViewStore.setSelectedPositionCandidateId(c)
	}, [c])

	useEffect(() => {
		return autorun(() => {
			if (!rootStore.organizations.activeOrganizationId) {
				return
			}

			rootStore.positions.fetchPositionCandidatesForCandidate(candidate.id)
		})
	}, [candidate.id])

	const columns = [
		{
			name: 'Name',
			selector: (row: IPosition & { referenceName?: string }) => {
				if (row.referenceName) {
					return `${row.name} (${row.referenceName})`
				}
				return row.name
			},
		},
		{
			name: 'Account',
			selector: (row: IPosition) =>
				rootStore.accounts.getAccountById(row.accountId)?.name ?? 'unknown',
			width: '200px',
		},
		{
			name: 'Stage',
			cell: (row: IPosition) => {
				return <PositionCandidateStage candidate={candidate} positionId={row.id} />
			},
			width: '300px',
		},
		{
			name: 'Actions',
			cell: (row: IPosition) => (
				<PositionCandidateActions candidate={candidate} position={row} />
			),
		},
	]

	const handleRowClicked = (row?: IPosition) => {
		if (row) {
			const pc = rootStore.positions.getPositionCandidate(candidate.id, row.id)
			if (pc) {
				candidateSlidingPanelStore.close()
				cardDetailedViewStore.setSelectedPositionCandidateId(pc?.id)

				// add query param to url
				router.push(
					{
						query: {
							...router.query,
							c: pc.id,
						},
					},
					undefined,
					{ shallow: true }
				)
			}
		} else {
			cardDetailedViewStore.close()
		}
	}

	return (
		<Tabs.Content
			value={TabKey.Positions}
			style={{ padding: '0px', paddingTop: '10px' }}
			className="h-96 overflow-y-auto"
		>
			<Table columns={columns} rows={positions} onRowClicked={handleRowClicked} />
		</Tabs.Content>
	)
}

type PositionCandidateActionsProps = {
	candidate: ICandidate
	position: IPosition
}
function PositionCandidateActions({ candidate, position }: PositionCandidateActionsProps) {
	const confirm = useConfirm()
	const [loading, setLoading] = useState({ outreach: false, airtable: false })

	const handlePushToAirtableATS = async (pc?: IPositionCandidate) => {
		if (!pc) {
			return
		}

		setLoading((prev) => ({ ...prev, airtable: true }))

		await rootStore.positions.sendToAirtable(pc)

		setLoading((prev) => ({ ...prev, airtable: false }))
	}

	const handleDeletePosition = async (position: IPosition) => {
		const shouldContinue = await confirm({
			title: 'Remove from Position',
			message: 'Are you sure you want to remove candiate from this position?',
			confirmText: 'Remove',
			confirmColor: 'error',
		})

		if (!shouldContinue) {
			return
		}

		await rootStore.positions.removeCandidate(position, candidate)
	}

	const handleOpenTrelloCard = (positionCandidate: IPositionCandidate) => {
		if (!positionCandidate || !positionCandidate.trelloCardUrl) {
			return
		}

		window.open(positionCandidate.trelloCardUrl, '_blank')
	}

	const pc = rootStore.positions.getPositionCandidate(candidate.id, position.id)
	return (
		<div className="flex items-center">
			<Button auto light color="error" onClick={() => handleDeletePosition(position)}>
				<HiTrash size="1.25em" />
			</Button>
			{pc?.trelloCardUrl && (
				<Tooltip content="Open Trello Card" color="secondary" placement="rightStart">
					<Button auto light onClick={() => handleOpenTrelloCard(pc)}>
						<HiExternalLink size="1.25em" />
					</Button>
				</Tooltip>
			)}
			<Tooltip content="Send to Airtable" color="warning" placement="rightStart">
				<Button
					auto
					light
					onClick={() => handlePushToAirtableATS(pc)}
					disabled={loading.airtable}
				>
					{loading.airtable ? (
						<Loading />
					) : (
						<HiArrowCircleUp className="w-5 h-5 fill-amber-600" />
					)}
				</Button>
			</Tooltip>
		</div>
	)
}
