import { authHeader, del, get, patch, post, put } from './api'

import type {
	IAccount,
	IAvailability,
	ICard,
	ICandidateEvent,
	IPositionCandidateAttachment,
	IPositionCandidateEvent,
	IPositionCandidateScheduledEvent,
	IResourceComment,
	ISequenceInstance,
	PositionCandidateQuestionOption,
	PositionStatus,
	Query,
	SequenceInstanceStatus,
} from '@touchpoints/requests'
import { positions } from '@touchpoints/requests'
import type { IPosition, IPositionCandidate, PositionProps } from '@types'

export const fetchPositions = positions.makeFetchPositions(get, authHeader)
export const fetchPositionCandidatesForCandidate = positions.makeFetchPositonCandidatesForCandidate(
	get,
	authHeader
)

export async function fetchPosition(organizationId: string, positionId: string) {
	const headers = await authHeader()
	return get<{ position: IPosition; accountsById: Record<string, IAccount> }>(
		`/organizations/${organizationId}/positions/${positionId}`,
		undefined,
		headers
	)
}

export async function createPosition(orgId: string, accountId: string, props: PositionProps) {
	const headers = await authHeader()
	return post<{ position: IPosition }>(
		`/organizations/${orgId}/accounts/${accountId}/positions`,
		{
			...props,
		},
		headers
	)
}

export async function updatePosition(
	orgId: string,
	accountId: string,
	positionId: string,
	data: Partial<PositionProps>
) {
	const headers = await authHeader()
	return patch<{ position: IPosition }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}`,
		{
			...data,
		},
		headers
	)
}

export async function deletePosition(orgId: string, accountId: string, positionId: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}`, headers)
}

export async function fetchAllPositionCandidates(orgId: string, status?: PositionStatus) {
	const headers = await authHeader()
	const response = await get<{ positionCandidates: IPositionCandidate[] }>(
		`/organizations/${orgId}/position-candidates`,
		{ status },
		headers
	)
	return response
}

export async function fetchPositionCandidatesByAccount(orgId: string, accountId: string) {
	const headers = await authHeader()
	const response = await get<{ positionCandidates: IPositionCandidate[] }>(
		`/organizations/${orgId}/accounts/${accountId}/position-candidates`,
		undefined,
		headers
	)

	return response
}

export async function fetchPositionCandidates(
	orgId: string,
	accountId: string,
	positionId: string
) {
	const headers = await authHeader()
	return get<{ positionCandidates: IPositionCandidate[] }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates`,
		undefined,
		headers
	)
}

export async function fetchPositionCandidate(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return get<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}`,
		undefined,
		headers
	)
}

export async function fetchPositionCandidateById(orgId: string, positionCandidateId: string) {
	const headers = await authHeader()
	return get<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/position-candidates/${positionCandidateId}`,
		undefined,
		headers
	)
}

export async function fetchPositionCandidateSequences(
	orgId: string,
	positionCandidateId: string,
	params?: { status?: SequenceInstanceStatus }
) {
	const headers = await authHeader()
	return get<{ sequenceInstances: ISequenceInstance[] }>(
		`/organizations/${orgId}/position-candidates/${positionCandidateId}/sequence-instances`,
		params,
		headers
	)
}

export async function addCandidateToPosition(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return put<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates`,
		{ candidateId },
		headers
	)
}

export async function removeCandidateFromPosition(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return del(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}`,
		headers
	)
}

export async function updateCandidatePosition(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string,
	data: Partial<
		Pick<
			IPositionCandidate,
			| 'stage'
			| 'engagementContactId'
			| 'recruiterId'
			| 'accountExecutiveId'
			| 'supportContactId'
			| 'status'
			| 'trelloCardUrl'
			| 'tags'
		>
	>
) {
	const headers = await authHeader()
	return patch<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}`,
		data,
		headers
	)
}

export async function runStageTriggers(
	orgId: string,
	accountId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return post(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/candidates/${candidateId}/run-stage-trigger`,
		undefined,
		headers
	)
}

export async function sendPositionCandidateToOutreach(orgId: string, positionCandidateId: string) {
	const headers = await authHeader()
	return post(
		`/organizations/${orgId}/experimental/position-candidates/${positionCandidateId}/outreach`,
		undefined,
		headers
	)
}

export async function sendPositionCandidateToAirtable(orgId: string, positionCandidateId: string) {
	const headers = await authHeader()
	return post(
		`/organizations/${orgId}/experimental/position-candidates/${positionCandidateId}/airtable`,
		undefined,
		headers
	)
}

export async function addAttachmentToPositionCandidate(
	orgId: string,
	positionId: string,
	candidateId: string,
	data: FormData
) {
	const headers = await authHeader()
	return post<{ success: boolean; message?: string; doc?: IPositionCandidateAttachment }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/attachments`,
		data,
		headers
	)
}

export async function deleteAttachmentFromPositionCandidate(
	orgId: string,
	positionId: string,
	candidateId: string,
	attachmentId: string
) {
	const headers = await authHeader()
	return del<{ attachment: IPositionCandidateAttachment }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/attachments/${attachmentId}`,
		headers
	)
}

export async function fetchPositionCandidateAttachments(
	orgId: string,
	positionId: string,
	candidateId: string
) {
	const headers = await authHeader()
	return get<IPositionCandidateAttachment[]>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/attachments`,
		undefined,
		headers
	)
}

export async function updateCandidatePositionChecklistItem(
	orgId: string,
	positionId: string,
	candidateId: string,
	actionItemId: string,
	checklistItemId: string,
	data: { completed: boolean }
) {
	const headers = await authHeader()
	return patch<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/action-items/${actionItemId}/checklist-items/${checklistItemId}`,
		data,
		headers
	)
}

export async function updateCandidatePositionQuestionItem(
	orgId: string,
	positionId: string,
	candidateId: string,
	actionItemId: string,
	questionItemId: string,
	data: { text?: string; options?: PositionCandidateQuestionOption[] }
) {
	const headers = await authHeader()
	return patch<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/action-items/${actionItemId}/question-items/${questionItemId}`,
		{
			...data,
			options: data.options?.map((o) => ({
				...o,
				actions: o.actions?.map((a) => ({ ...a })),
			})),
		},
		headers
	)
}

export async function removeCandidatePositionActionItem(
	orgId: string,
	positionId: string,
	candidateId: string,
	actionItemId: string
) {
	const headers = await authHeader()
	return del<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/action-items/${actionItemId}`,
		headers
	)
}

export async function addCandidateEvent(
	orgId: string,
	positionId: string,
	candidateId: string,
	data: Partial<IPositionCandidateScheduledEvent>
) {
	const headers = await authHeader()
	return put<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/scheduled-events`,
		{ ...data, timedEventInstances: data.timedEventInstances?.map((t) => ({ ...t })) },
		headers
	)
}

export async function updateCandidateEvent(
	orgId: string,
	positionId: string,
	candidateId: string,
	eventId: string,
	data: Partial<IPositionCandidateScheduledEvent>
) {
	const headers = await authHeader()
	return patch<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/scheduled-events/${eventId}`,
		{ ...data, timedEventInstances: data.timedEventInstances?.map((t) => ({ ...t })) },
		headers
	)
}

export async function deleteCandidateEvent(
	orgId: string,
	positionId: string,
	candidateId: string,
	eventId: string
) {
	const headers = await authHeader()
	return del<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/scheduled-events/${eventId}`,
		headers
	)
}

export async function updateCandidateEventCompleted(
	orgId: string,
	positionId: string,
	candidateId: string,
	eventId: string,
	value: boolean
) {
	const headers = await authHeader()
	return post<{ positionCandidate: IPositionCandidate }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/scheduled-events/${eventId}/complete`,
		{ value },
		headers
	)
}

export async function fetchPositionAvailability(
	orgId: string,
	accountId: string,
	positionId: string
) {
	const headers = await authHeader()
	return get<{ availability: IAvailability; interviews: IAvailability[] }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/availability`,
		undefined,
		headers
	)
}

export async function createPositionAvailability(
	orgId: string,
	accountId: string,
	positionId: string
) {
	const headers = await authHeader()
	return post<{ availability: IAvailability }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/availability`,
		undefined,
		headers
	)
}

export async function updatePositionAvailability(
	orgId: string,
	accountId: string,
	positionId: string,
	availabilityId: string,
	data: Partial<Omit<IAvailability, 'id' | 'positionId'>>
) {
	const headers = await authHeader()
	return patch<{ availability: IAvailability }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/availability/${availabilityId}`,
		data,
		headers
	)
}

export async function removePositionAvailability(
	orgId: string,
	accountId: string,
	positionId: string,
	data: { ids: string[] }
) {
	const headers = await authHeader()
	return del<{ deletedIds: string[] }>(
		`/organizations/${orgId}/accounts/${accountId}/positions/${positionId}/availability`,
		headers,
		data
	)
}

export async function addCandidateComment(
	orgId: string,
	positionId: string,
	candidateId: string,
	comment: string,
	options: {
		plainComment?: string
		taskId?: string
	} = {}
) {
	const headers = await authHeader()
	return post<{ comment: IResourceComment }>(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/comments`,
		{ comment, ...options },
		headers
	)
}

export async function updateCandidateComment(
	orgId: string,
	positionId: string,
	candidateId: string,
	commentId: string,
	comment: string
) {
	const headers = await authHeader()
	return patch(
		`/organizations/${orgId}/positions/${positionId}/candidates/${candidateId}/comments/${commentId}`,
		{ comment },
		headers
	)
}
